<template>
    <nav id="MainNavbar" class="navbar navbar-expand-md shadow-sm">
        <a class="navbar-brand" :href="isCurrentPage('/login') ? 'https://www.intelligentwellheadsystems.com/' : '/home'" ref="logo">
            <div class="position-relative">
                <img v-if="isCurrentPage('/login')" id="imgIWSLogo" src="/images/IWS_Logo_Rev.png" height="40" alt="IWSLogo" >
                <img v-else id="imgLogo" src="/images/inVision_rebrand_logo_2021.png" height="30" alt="IWS" >
                <div v-if="isPreProd" class="preprod_banner_text">
                    PRE-PROD!
                </div>
            </div>
        </a>

        <!-- If there is not enough information to find the current job, the page isn't one that makes use of the job select / stages completed (it's not a job specific page) -->
        <template v-if="!!job && !!job.jobNumber">
            <span v-if="!_isNullOrEmpty(filteredJobs)" id="job-select" :class="{ 'extend-job-select': !percentCompleted || percentCompleted <= 0 }">
                <iws-select
                    :value="job.jobNumber"
                    :options="filteredJobs"
                    display-name="displayName"
                    value-name="jobNumber"
                    @change="onJobChange"
                />
            </span>

            <span id="stages-completed">
                <iws-button v-if="percentCompleted > 0" type="outline-light" style="width: 300px; margin-left: 15px;">
                    <template #text>
                        Stages Completed: {{ totalCompletedStages }}/{{ numberOfStagesAllWells }} <span class="ml-1 warning-text-color">({{ percentCompleted }}%)</span>
                    </template>
                </iws-button>
            </span>

            <span v-if="!!localTime || !!weather" id="local-info">
                <iws-button type="outline-light" style="margin-left: 15px;" :click="openWeatherModal">
                    <template #text>
                        <span class="local-info-time">
                            {{ localTime }} 
                        </span>

                        <template v-if="!_isFalsy(weather) && !_isFalsy(weather.currentConditions)">
                            <span v-if="!!localTime" class="local-info-time" style="margin: 0px -5px 0px 5px">|</span>

                            <img
                                :src="grabWeatherIcon(weather.currentConditions.iconCode)"
                                style="height: 20px"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                :title="weather.currentConditions.phrase"
                            />

                            <span class="local-info-weather">
                                <span style="position: relative; left: -5px; top: 1px">
                                    {{ Math.round(weather.currentConditions.temperature.value) }}&deg; {{ weather.currentConditions.temperature.unit }} 
                                </span>

                                <span style="margin: 0px 5px">|</span> 
                                {{ Math.round(weather.currentConditions.wind.speed.value) }}{{ user.weather_unit === 0 ? 'mph' : 'km/h' }} {{ weather.currentConditions.wind.direction.localizedDescription }}

                                <span v-if="!_isNullOrEmpty(weather.severeWeather)" class="ml-2" id="severe-weather">
                                    <i class="fas fa-cloud-sun-rain" :class="severeWeatherAlertLevel ? 'danger-text-color' : 'warning-text-color'" />
                                    <span id="contains-alert-dot" :style="`background-color: var(--${severeWeatherAlertLevel ? 'danger' : 'warning'}-color) !important`"></span>
                                </span>
                            </span>
                        </template>
                    </template>
                </iws-button>
            </span>
        </template>

        <ul v-if="!!user" class="navbar-nav ml-auto">
            <template v-if="!!job && !!job.jobNumber">
                <li id="dashboard-alerts-bell">
                    <span 
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dashboard Alerts"
                        class="clickable"
                        :class="{ 'danger-text-color': triggerAlert && containsDashboardAlert }"
                        @click="openDashboardAlerts"
                    >
                        <i class="fas fa-bell"></i>

                        <span v-if="containsDashboardAlert" id="contains-alert-dot" class="danger-text-color"></span>
                    </span>
                </li>

                <camera-button v-if="permissionsMap.Camera"
                    :job-number="job.jobNumber"
                />

                <li v-if="!_isNullOrEmpty(comms)">
                    <span data-toggle="tooltip" data-placement="bottom" title="SiteComms">
                        <a>
                            <comms-widget
                                :user="user"
                                :job-number="job.jobNumber"
                            />
                        </a>
                    </span>
                </li>
            </template>

            <li class="clickable">
                <span data-toggle="tooltip" data-placement="bottom" title="Home">
                    <a href="/home" :class="{ 'active-dropdown-item': isCurrentPage('/home') }">
                        <i class="fas fa-home"></i>
                    </a>
                </span>
            </li>

            <li v-if="reducedWidth" class="clickable position-relative">
                <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <a id="mobileMenu">
                        <i class="fas fa-bars"></i>
                    </a>
                </span>

                <div id="mobileMenuDropdown" class="dropdown-menu dropdown-menu-right" aria-labelledby="mobileMenu">
                    <template v-if="!_isNullOrEmpty(filteredReports)">
                        <a class="dropdown-item" :class="{ 'show': openTabs.reports }" @click.stop="openTabs.reports = !openTabs.reports">
                            <b>Reports</b>
                            <span style="float: right">
                                <i class="fas fa-chevron-down"></i>
                            </span>
                        </a>
                        <template v-if="openTabs.reports">
                            <a v-for="reportPage in filteredReports" class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage(reportPage.link) }" :href="reportPage.link" style="padding-left: 35px;">
                                &#9675; {{ reportPage.title }}
                            </a>
                        </template>
                    </template>

                    <template v-if="!_isNullOrEmpty(filteredAdmin)">
                        <a class="dropdown-item" :class="{ 'show': openTabs.admin }" @click.stop="openTabs.admin = !openTabs.admin">
                            <b>Admin</b>
                            <span style="float: right">
                                <i class="fas fa-chevron-down"></i>
                            </span>
                        </a>
                        <template v-if="openTabs.admin">
                            <a v-for="adminPage in filteredAdmin" class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage(adminPage.link) }" :href="adminPage.link" style="padding-left: 35px;">
                                &#9675; {{ adminPage.title }}
                            </a>
                        </template>
                    </template>

                    <a href="/release-notes" @click="clearNewVersionBadge()" class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage('/release-notes') }">
                        Release Notes
                    </a>

                    <a data-toggle="modal" data-target="#supportModal" class="dropdown-item">
                        Support
                    </a>

                    <a href="/account-settings" class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage('/account-settings') }">
                        Profile
                    </a>
                    <a v-if="novaImpersonatedBy" href="/impersonation/stop" class="dropdown-item">
                        Stop Impersonation
                    </a>
                    <a @click="logout" class="dropdown-item clickable">
                        Logout
                    </a>
                </div>
            </li>
            <template v-else>
                <li v-if="!_isNullOrEmpty(filteredReports)" class="clickable position-relative">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <a id="reportsMenu" :class="{ 'active-dropdown-item': reportPageIsActive }" data-toggle="tooltip" data-placement="bottom" title="Reports">
                            <i class="far fa-file-alt"></i>
                        </a>
                    </span>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="reportsMenu">
                        <a v-for="reportPage in filteredReports" class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage(reportPage.link) }" :href="reportPage.link">
                            {{ reportPage.title }}
                        </a>
                    </div>
                </li>

                <li v-if="!_isNullOrEmpty(filteredAdmin)" class="clickable position-relative">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <a id="adminMenu" :class="{ 'active-dropdown-item': isCurrentPage('/release-notes') }" data-toggle="tooltip" data-placement="bottom" title="Support">
                            <i class="fas fa-question-circle"></i>

                            <span id="newVersionNotification" style="display: none"></span>
                        </a>
                    </span>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="adminMenu">
                        <a class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage('/release-notes') }" href="/release-notes" @click="clearNewVersionBadge()">
                            <span id="newVersionBadge" style='color: red; vertical-align: top; font-size: 0.75rem;'></span>

                            Release Notes
                        </a>

                        <a class="dropdown-item" data-toggle="modal" data-target="#supportModal">
                            Support
                        </a>
                    </div>
                </li>

                <li v-if="!_isNullOrEmpty(filteredAdmin)" class="clickable position-relative">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <a id="adminMenu" :class="{ 'active-dropdown-item': adminPageIsActive }" data-toggle="tooltip" data-placement="bottom" title="Admin">
                            <i class="fas fa-cog"></i>
                        </a>
                    </span>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="adminMenu">
                        <a v-for="adminPage in filteredAdmin" class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage(adminPage.link) }" :href="adminPage.link">
                            {{ adminPage.title }}
                        </a>
                    </div>
                </li>

                <li class="position-relative">
                    <span id="userMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <a data-toggle="tooltip" data-placement="bottom" title="Profile">
                            <span :style="`background-color: ${colour(email)}`" class="circle clickable">
                                {{ fullUserName }}
                            </span>
                        </a>
                    </span>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userMenu">
                        <a href="/account-settings" class="dropdown-item" :class="{ 'active-dropdown-item': isCurrentPage('/account-settings') }">
                            Profile
                        </a>
                        <a v-if="novaImpersonatedBy" href="/impersonation/stop" class="dropdown-item">
                            Stop Impersonation
                        </a>
                        <a @click="logout" class="dropdown-item clickable">
                            Logout
                        </a>
                    </div>
                </li>
            </template>
        </ul>
        <ul v-else class="navbar-nav ml-auto">
            <li class="clickable" data-toggle="modal" data-target="#supportModal">
                <a>
                    <i class="fas fa-question-circle"></i>
                </a>
            </li>
        </ul>

        <dashboard-alerts-modal ref="DashboardAlertsModal" :tags="tags" />

        <weather-details-modal v-if="!!weather" ref="WeatherDetailsModalashboardAlertsModal" :weather="weather" :job="job" :user="user" />
   </nav>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, isFeatureFlagged } = GlobalFunctions;

import DateFunctions from '../DateFunctions.js';
const { applyTimeOffset } = DateFunctions;

import JobMixin from '../mixins/JobMixin.js';
import eventBus from "../eventBus";


import DashboardAlertsModal from './DashboardComponents/DashboardAlertsModal';
import WeatherDetailsModal from './WeatherDetailsModal';

import dashboardAlertStore, { initDashAlerts } from '../store/dashboardAlerts.js'

export default {
    components: {
        DashboardAlertsModal,
        WeatherDetailsModal
    },
    
    props: {
        isPreProd: {
            type: Boolean,
            required: true
        },

        user: {
            type: Object,
            required: false
        },
        job: {
            type: Object,
            required: true
        },
        jobs: {
            type: Array,
            required: true
        },
        permissionsMap: {
            type: Object,
            required: true
        },
        novaImpersonatedBy: {
            required: true
        },

        isAdmin: {
            type: [Boolean, Number],
            required: true
        },
        isCompanyAdmin: {
            type: [Boolean, Number],
            required: true
        },
        iwsUser: {
            type: [Boolean, Number],
            required: true
        },

        // Stages Completed tings
        totalCompletedStages: {
            type: [Array, Object, Number]
        },
        wells: {
            type: Array,
            required: true
        },
        comms:{
            type: Array,
            required: true
        },
        tags: {
            type: Array,
            required: true
        }
    },

    mixins: [JobMixin],

    data: () => ({
        reducedWidth: false,
        openTabs: {
            reports: false,
            admin: false
        },
        latestDataCollection: [],
        utcDifference: 0,

        alertInterval: null,
        alertSoundInterval: null,
        triggerAlert: false,
        dashAlertAlarm: new Audio('/audio/notification-pluck-on-slower-269285.mp3'),

        localTime: null,
        weather: null,
        extemeSevereWeather: ['FIRE', 'TORNADO']
    }),

    computed: {
        email() {
            return this.user?.email;
        },
        fullUserName() {
            return `${this.user?.first_name[0]} ${this.user?.last_name[0]}`
        },
        hasAdmin() {
            return this.isAdmin || this.isCompanyAdmin;
        },
        hasAdminOrIws() {
            return this.hasAdmin || this.iwsUser;
        },
        hasAdminAndIws() {
            return this.hasAdmin && this.iwsUser;
        },

        severeWeatherAlertLevel: function() {
            return !isNullOrEmpty(this.weather?.severeWeather) && !isFalsy(this.weather?.severeWeather.find(_weather => this.extemeSevereWeather.includes(_weather.category)));
        },

        containsDashboardAlert() {
            return !isNullOrEmpty(dashboardAlertStore.alertingTags);
        },

        filteredJobs() {
            if (isNullOrEmpty(this.jobs))
                return [];
            return this.jobs
                .filter(job => !job.jobNumber || !job.jobNumber.includes('TS'))
                .map(job => {
                    return {
                        ...job,
                        displayName: `${job.jobNumber} - ${this.jobMainTitle(job)} ${this.iwsUser && job.system ? `- (${job.system})` : ''}`
                    }
                });
        },

        filteredReports() {
            const { jobNumber } = this.job

            // All report pages are job specific, if it isn't found we can't go to report pages
            if (isFalsy(jobNumber))
                return [];

            const reportPages = [
                { title: 'NPT Report', link: `/npt-report/${jobNumber}`, condition: this.permissionsMap.NPTReport || this.permissionsMap.TimeKeeper },
                { title: 'Time Keeper', link: `/time-keeper/${jobNumber}`, condition: this.permissionsMap.TimeKeeper },
                { title: 'Frac Stage Summary', link: `/frac-stage-summary/${jobNumber}`, condition: this.permissionsMap.FracStageSummary },
                { title: 'Wireline Stage Summary', link: `/wireline-stage-summary/${jobNumber}`, condition: this.permissionsMap.WirelineStageSummary },
                { title: 'Wireline Plug and Perf', link: `/wireline-op/${jobNumber}/history/0/1/5`, condition: this.permissionsMap.WirelinePlugandPerf },
                { title: 'Tag Summary', link: `/tag-summary/${jobNumber}`, condition: this.permissionsMap.TagSummary },
                { title: 'Valve Actuation Report', link: `/valve-report/${jobNumber}`, condition: true},
                  ...!isFeatureFlagged('PROCESS_LOGS')  ? [] : [{ title: 'Process Logs', link: `/process-logs/${jobNumber}`, condition: this.permissionsMap.ProcessLogs }]
            ];

            // IwsUsers automatically get access to everything
            return this.iwsUser ? reportPages : reportPages.filter(_page => _page.condition);
        },
        filteredAdmin() {
            const reportPages = [
                { title: 'User Management', link: `/userManagement`, condition: this.hasAdmin },
                { title: 'New Job', link: `/jobs/create`, condition: this.isAdmin && this.permissionsMap.isDebug },
                { title: 'Copy Remote Job', link: `/distinctJobs`, condition: this.isAdmin && this.permissionsMap.isDebug && this.permissionsMap.allowCopy },
                { title: 'Copy Remote Data', link: `/copy-data`, condition: this.isAdmin && this.permissionsMap.isDebug && this.permissionsMap.allowCopy },
                { title: 'Gun Library', link: `/guns`, condition: this.hasAdminOrIws },
                { title: 'Plug Library', link: `/plugs`, condition: this.hasAdminOrIws },
                { title: 'Contractors', link: `/contractors`, condition: this.hasAdminOrIws },
                { title: 'Camera Management', link: `/cameras/settings`, condition: this.hasAdminAndIws },
                { title: 'Tooltip Management', link: `/tooltipManagement`, condition: this.hasAdminAndIws },
                { title: 'Alert Banner', link: `/alertBanner`, condition: this.hasAdminAndIws },
                { title: 'Nova Dashboard', link: `route('nova.pages.home')`, condition: this.user?.roles?.find(_role => _role.name == 'novaAdmin') }
            ];

            return reportPages.filter(_page => _page.condition);
        },

        reportPageIsActive() {
            return !!this.filteredReports?.find(reportPage => this.isCurrentPage(reportPage.link));
        },
        adminPageIsActive() {
            return !!this.filteredAdmin?.find(adminPage => this.isCurrentPage(adminPage.link));
        },

        // Stages Completed tings
        numberOfStagesAllWells() {
            return this.wells?.reduce((a, b) => a + b.numberOfStages, 0) || 0;
        },
        percentCompleted() {
            return (this.totalCompletedStages / this.numberOfStagesAllWells * 100).toFixed(1);
        }
    },

    methods: {
        _isFalsy(val) {
            return isFalsy(val);
        },
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },
        _isFeatureFlagged(val) {
            return isFeatureFlagged(val);
        },

        colour(string) {
            if (!string)
                return 'primary'

            var colors = ["#e51c23", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#5677fc", "#03a9f4", "#00bcd4", "#009688", "#259b24", "#8bc34a", "#afb42b", "#ff9800", "#ff5722", "#795548", "#607d8b"]

            var hash = 0;
            for (const char in string) {
                hash = char + ((hash << 5) - hash);
                hash = hash & hash;
            }
            hash = ((hash % colors.length) + colors.length) % colors.length;
            return colors[hash];
        },

        isCurrentPage(page) {
            return window.location.href.includes(page);
        },

        onJobChange(jobNumber) {
            window.location.href = window.location.href.replace(this.job.jobNumber, jobNumber);
        },
        clearNewVersionBadge() {
            localStorage.setItem('dashboardHasNewVersion', false);
        },
        logout(event) {
            event.preventDefault();
            document.cookie = 'iws_username= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.getElementById('logout-form').submit();
        },

        openDashboardAlerts() {
            return this.$refs?.DashboardAlertsModal?.open();
        },
        openWeatherModal: function() {
            if (!isFalsy(this.weather?.currentConditions))
                return this.$refs?.WeatherDetailsModalashboardAlertsModal?.open();
        },

        fetchWeather: function() {
            return axios.get(`/${this.job.jobNumber}/weather`).then(_weather => {
                this.weather = _weather.data;
            });
        },
        grabWeatherIcon(iconCode) {
            return `/images/weather-icons/${iconCode}.png`;
        },

        setLocalTime: function(date=new Date(), hourOffset=this.job.hourOffset) {
            this.localTime = applyTimeOffset(date, hourOffset, this.user.clock_format === 0 ? 'MMMM DD, YYYY, h:mm A' : 'MMMM DD, YYYY, HH:mm');
        }
    },

    watch: {
        containsDashboardAlert() {
            // Run an interval every second to simulate flashing only if there is a notifiction
            if (this.containsDashboardAlert) {
                // Just double check it's not already running for safety
                if (this.alertInterval == null) {
                    this.alertInterval = setInterval(() => {
                        this.triggerAlert = !this.triggerAlert;
                        eventBus.$emit('trigger-alert-change', this.triggerAlert);
                    }, 500)
                    this.alertSoundInterval = setInterval(() => this.dashAlertAlarm?.play(), 2000)
                }
            } else {
                clearInterval(this.alertInterval);
                this.alertInterval = null;

                clearInterval(this.alertSoundInterval);
                this.alertSoundInterval = null;
            }
        }
    },

    created() {
        initDashAlerts(this.user, this.job);
    },
    mounted() {
        // On small screens, reduce the width of certain components so everything fits
        let _this = this;
        const testWidth = () => {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            _this.reducedWidth = !!this.job?.jobNumber ? vw < 1000 : vw < 450;
        }
        testWidth();
        window.addEventListener('resize', testWidth, true);

        if (!isFalsy(this.job?.jobNumber)) {
            this.setLocalTime();
            setInterval(this.setLocalTime, 1000);

            this.fetchWeather();
            setInterval(this.fetchWeather, 1200000);
        }
    }
};

</script>

<style>
    #job-select:not(.extend-job-select) .custom-search-select .container {
        width: 615px !important;
        position: fixed !important;
        left: 188px !important;
        top: 49px !important;
    }

    .toast.dash-alert {
        position: relative;
        right: 150px;

        width: 500px !important;
        max-width: 500px !important;
    }
    .toast.dash-alert .toast-body button {
        background-color: #28a745 !important;
        border-color: #28a745 !important;
    }
</style>
<style scoped>
    @media only screen and (min-width: 1045px) {
        /* 350px is the ideal width to see full job details
        if the screen is not big enough though just allow normal width */
        #job-select {
            width: 300px;
        }
        #job-select.extend-job-select {
            width: 615px !important;
        }
    }
    @media only screen and (max-width: 770px) {
        /* On very small screens the navbar falls apart not being able to fit everything
        Just hide the stages completed on very small screens */
        #stages-completed {
            display: none;
        }
    }

    #MainNavbar.navbar {
        height: 62px;

        background-color: #242A30;
        border-bottom: 1px solid #000;
    }

    /* Battling Bootstrap trying to be mobile responsive but actually making it worse */
    .navbar-nav {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    #dashboard-alerts-bell span {
        transition: 0.1s color;
    }
    #dashboard-alerts-bell:hover {
        color: #5F6974;
    }

    #contains-alert-dot {
        position: absolute;
        top: 3px;
        right: 9px;

        display: block;

        background-color: var(--danger-color);

        height: 10px;
        width: 10px;
        border-radius: 50%;
    }

    .navbar-nav li,
    .navbar-nav li>span {
        height: 30px;
    }
    .navbar-nav li>span {
        display: inline-block;
        width: 44px;
        text-align: center;
        font-size: 18px;

        transition: 0.25s background-color;
        position: relative;
    }
    .navbar-nav li>span>a {
        display: inline-block;
        height: 100%;
        width: 100%;
    }
    .navbar-nav li:not(#dashboard-alerts-bell) i {
        color: white;
    }
    .navbar-nav li i {
        margin-top: 6px;
    }
    .navbar-nav li:not(:last-child) {
        border-right: 1px solid #5F6974 !important;
    }

    .navbar-nav li.clickable span:hover {
        background-color: #475467;
    }

    .circle {
        padding-top: 5px;
        border-radius: 50%;

        width: 35px;
        height: 35px;

        font-size: 16px;
        text-align: center;

        position: relative;
        top: -2px;
        left: 10px;

        display: inline-block;
    }

    .dropdown-menu {
        padding: 0px !important;
    }
    .dropdown-menu,
    .dropdown-menu a:focus,
    .dropdown-menu a:active {
        background-color: #343A40;
    }
    .dropdown-menu a {
        color: #FFFFFF !important;
        padding: .5rem 1.5rem;
    }
    .dropdown-menu a:hover {
        background-color: #475467;
    }
    .dropdown-menu a:not(:last-child) {
        border-bottom: 1px solid #7B8A98;
    }
    #mobileMenuDropdown .dropdown-item.show>span {
        transition: all 0.5s;
    }
    #mobileMenuDropdown .dropdown-item.show>span {
        rotate: 180deg;
    }

    .active-dropdown-item {
        border-bottom: 2px solid #007bff !important;
    }

    .notification-shown {
        position: absolute;
        top: 3px;
        right: 10px;

        height: 10px;
        width: 10px;

        background-color: red;
        border-radius: 50%;

        display: inline !important;
    }

    .preprod_banner_text {
        position: absolute;
        top: 18px;
        left: -30px;
        transform: translate(50%, -50%);
        color: #AA3333;
        font-weight: 900;
        text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
        animation: blinker 2s linear infinite;
    }
    @keyframes blinker {
        25% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
    
    /* As the screen size reduces, hide local info to avoid wrapping */
    @media screen and (max-width: 1450px) {
        .local-info-time {
            display: none;
        }
    }

    @media screen and (max-width: 1300px) {
        .local-info-weather {
            display: none;
        }
    }
</style>
