var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      title: "Job Site Weather Details",
      "show-modal": _vm.showSevereWeather,
      "max-width": "900px",
      "max-height": "90vh",
      "secondary-button-visible": false,
      "primary-button-text": "Close",
    },
    on: {
      close: function ($event) {
        _vm.showSevereWeather = false
      },
      "primary-action": function ($event) {
        _vm.showSevereWeather = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c("div", [
                _c("span", { staticStyle: { "font-size": "84px" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        Math.round(
                          _vm.weather.currentConditions.temperature.value
                        )
                      ) +
                      "°\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-size": "42px" } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.weather.currentConditions.temperature.unit) +
                      "\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "label-text-size" }, [
                _c("img", {
                  staticStyle: { height: "38px" },
                  attrs: {
                    src: _vm.grabWeatherIcon(
                      _vm.weather.currentConditions.iconCode
                    ),
                  },
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.weather.currentConditions.phrase) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "body-text-size" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$parent.localTime) +
                    "\n            "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row", attrs: { id: "current-conditions-row" } },
              [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "label-text-size-reduced" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.weather.currentConditions.precipitationSummary
                            .pastHour.value
                        ) +
                        _vm._s(
                          _vm.weather.currentConditions.precipitationSummary
                            .pastHour.unit
                        ) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "body-text-size-reduced" }, [
                    _vm._v(
                      "\n                    Precipitation\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "label-text-size-reduced" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          Math.round(
                            _vm.weather.currentConditions.wind.speed.value
                          )
                        ) +
                        _vm._s(_vm.user.weather_unit === 0 ? "mph" : "km/h") +
                        " " +
                        _vm._s(
                          _vm.weather.currentConditions.wind.direction
                            .localizedDescription
                        ) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "body-text-size-reduced" }, [
                    _vm._v("\n                    Wind\n                "),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "label-text-size-reduced" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.weather.currentConditions.visibility.value) +
                        _vm._s(_vm.weather.currentConditions.visibility.unit) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "body-text-size-reduced" }, [
                    _vm._v(
                      "\n                    Visibility\n                "
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            !("forecast" in _vm.weather)
              ? _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "spinner-border spinner-border loading-spinner-position",
                      attrs: { role: "status" },
                    },
                    [
                      _c("span", { staticClass: "sr-only mb-5" }, [
                        _vm._v("Loading..."),
                      ]),
                    ]
                  ),
                ])
              : _vm.weather.forecast && !!_vm.weather.forecast.length
              ? _c(
                  "div",
                  { staticClass: "row", attrs: { id: "forecast-row" } },
                  _vm._l(_vm.weather.forecast, function (forecastWeather) {
                    return _c(
                      "div",
                      {
                        key: `forecast${forecastWeather.date}`,
                        staticClass: "col",
                        attrs: {
                          "data-toggle": "tooltip",
                          title: forecastWeather.iconPhrase,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.grabWeatherIcon(forecastWeather.iconCode),
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "label-text-size-reduced" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                Math.round(forecastWeather.temperature.value)
                              ) +
                              "°\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(forecastWeather.temperature.unit) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "body-text-size-reduced" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._applyTimeOffset(forecastWeather.date)
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.weather.severeWeather, function (weather) {
              return _c(
                "div",
                { staticClass: "severe-weather-details" },
                [
                  _c("div", { staticClass: "label-text-size" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(weather.description.english) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(weather.alertAreas, function (alert) {
                    return _c("div", { staticClass: "body-text-size" }, [
                      _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(alert.name) +
                            "\n\n                    "
                        ),
                        _c("span", { staticClass: "float-right" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm._applyTimeOffset(alert.startTime)) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(
                        "\n\n                " +
                          _vm._s(alert.summary) +
                          "\n            "
                      ),
                    ])
                  }),
                ],
                2
              )
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-secondary-action",
        fn: function () {
          return [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "12px",
                  position: "absolute",
                  left: "1rem",
                },
              },
              [
                _vm._v(
                  "\n            Last updated " +
                    _vm._s(_vm._fromNow(_vm.weather.date)) +
                    " ago via "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.accuweather.com/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("AccuWeather")]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }