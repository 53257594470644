import Vue from 'vue';
window.Vue = Vue;

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import PortalVue from 'portal-vue';
import draggable from 'vuedraggable';

window._ = require('lodash');
window.axios = require("axios");

require('./directives.js');

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(PortalVue);
Vue.use(draggable);

// Do not lazy load some core components
Vue.component('main-navbar', require('./components/MainNavbar.vue').default);
Vue.component('dashboard-navbar', require('./components/DashboardNavbar.vue').default);

// Some components (typically onces referenced in app.blade.php) cannot be lazy loaded
Vue.component('alert-banner-component', require('./components/AlertBannerComponent.vue').default);

// Library common components
Vue.component('iws-modal', () => import('./components/Common/iwsModal.vue'));
Vue.component('iws-breadcrumbs', () => import('./components/Common/iwsBreadcrumbs.vue'));
Vue.component('iws-accordian', () => import('./components/Common/iwsAccordian.vue'));
Vue.component('iws-tabs', () => import('./components/Common/iwsTabs.vue'));
Vue.component('iws-table', () => import('./components/Common/iwsTable.vue'));
Vue.component('iws-confirm', () => import('./components/Common/iwsConfirm.vue'));

// Library form components
Vue.component('iws-input', () => import('./components/Forms/iwsInput.vue'));
Vue.component('iws-button', () => import('./components/Forms/iwsButton.vue'));
Vue.component('iws-select', () => import('./components/Forms/iwsSelect.vue'));
Vue.component('iws-checkbox', () => import('./components/Forms/iwsCheckbox.vue'));
Vue.component('iws-search', () => import('./components/Forms/iwsSearch.vue'));
Vue.component('iws-slider', () => import('./components/Forms/iwsSlider.vue'));
Vue.component('iws-switch', () => import('./components/Forms/iwsSwitch.vue'));
Vue.component('iws-colour-picker', () => import('./components/Forms/iwsColourPicker.vue'));
Vue.component('iws-date-time-picker', () => import('./components/Common/iwsDateTimePicker.vue'));
Vue.component('iws-form', () => import('./components/Forms/iwsForm.vue'));

Vue.component('base-table-view', () => import('./components/BaseTableViewComponent.vue'));
Vue.component('accordion', () => import('./components/AccordionComponent.vue'));

Vue.component('iws-alert', () => import('./components/Common/iwsAlert.vue'));
Vue.component('iws-badge', () => import('./components/Common/iwsBadge.vue'));

Vue.component('home', () => import('./components/Home/Home.vue'));

//Metadata management
Vue.component('metadata-component', () => import('./components/Metadata/MetadataComponent.vue'));
Vue.component('metadata-top-level', () => import('./components/Metadata/MetadataTopLevel.vue'));
Vue.component('metadata-operator-component', () => import('./components/Metadata/MetadataOperator.vue'));
Vue.component('structure-node-component', () => import('./components/StructureNodeComponent.vue'));
Vue.component('child-table-component', () => import('./components/ChildTableComponent.vue'));
Vue.component('manage-systems-component', () => import('./components/ManageSystems.vue'));

Vue.component('gmap-cluster', () => import('vue2-google-maps/dist/components/cluster'));
Vue.component('password-strength-component', () => import('./components/PasswordStrengthComponent.vue'));
Vue.component('change-password-component', () => import('./components/ChangePasswordComponent.vue'));
Vue.component('latency-component', () => import('./components/LatencyComponent.vue')); //widget
Vue.component('latency-component-new', () => import('./components/LatencyComponentNew.vue')); //widget
Vue.component('wireline-info-component', () => import('./components/WirelineInfoComponent.vue')); //widget
Vue.component('single-well-display-component', () => import('./components/SingleWellDisplayComponent.vue'));
Vue.component('analytics-dashboard-list', () => import('./components/Analytics/AnalyticsDashboardList.vue'));
Vue.component('analytics-dashboard', () => import('./components/Analytics/AnalyticsDashboard.vue'));
Vue.component('analytics-create-modal', () => import('./components/Analytics/AnalyticsCreateModal.vue'));
Vue.component('wireline-comment-modal', () => import('./components/WirelineCommentModal.vue'));
Vue.component('resolve-shot-modal', () => import('./components/ResolveShotModal.vue'));
Vue.component('add-well-component', () => import('./components/AddWellComponent.vue'));
Vue.component('activity-alert-component', () => import('./components/ActivityAlertComponent.vue')); //widget
Vue.component('flex-table-layout', () => import('./components/FlexTableLayout.vue'));
Vue.component('stage-dial-component', () => import('./components/StageDialComponent.vue'));
Vue.component('add-remove-buttons', () => import('./components/AddRemoveController.vue'));
Vue.component('how-to-component', () => import('./components/HowToComponent.vue'));
Vue.component('description-component', () => import('./components/DescriptionComponent.vue'));
Vue.component('perforation-report-component', () => import('./components/PerforationReportComponent.vue'));
Vue.component('tool-string-details-component', () => import('./components/ToolStringDetailsComponent.vue'));
Vue.component('add-well-long-component', () => import('./components/AddWellLongComponent.vue'));
Vue.component('comment-component', () => import('./components/CommentComponent.vue'));
Vue.component('comment-card', () => import('./components/CommentCard.vue'));
Vue.component('handshake-component', () => import('./components/handshakeComponent.vue'));
Vue.component('well-component', () => import('./components/WellComponent.vue'));
Vue.component('toolstring-config-component', () => import('./components/ToolstringConfigComponent.vue'));
Vue.component('toolstring-construction-modal', () => import('./components/ToolstringConstructionModal.vue'));
Vue.component('well-duplicate-modal', () => import('./components/WellDuplicateModal.vue'));
Vue.component('toolstring-duplicate-modal', () => import('./components/ToolstringDuplicateModal.vue'));
Vue.component('toolstring-stage-modal', () => import('./components/ToolstringStageModal.vue'));

Vue.component('cluster-spacing-config', () => import('./components/ClusterSpacingConfig.vue'));
Vue.component('linechart-component', () => import('./components/linechartComponent.vue'));
Vue.component('dashboard-well-component', () => import('./components/DashboardWellComponent.vue'));
Vue.component('job-component', () => import('./components/JobComponent.vue'));
Vue.component('setting-component', () => import('./components/SettingComponent.vue'));
Vue.component('setting-individual-component', () => import('./components/SettingIndividualComponent.vue'));
Vue.component('enter-pin-component', () => import('./components/EnterPINComponent.vue'));
Vue.component('alarm-component', () => import('./components/AlarmComponent.vue'));
Vue.component('messages-toolbar-component', () => import('./components/MessagesToolbarComponent.vue'));
Vue.component('messages-component', () => import('./components/MessagesComponent.vue'));
Vue.component('warnings-component', () => import('./components/WarningsComponent.vue'));
Vue.component('profile-history-component', () => import('./components/ProfileHistoryComponent.vue'));
Vue.component('job-list-component', () => import('./components/JobListComponent.vue'));
Vue.component('gun-list-component', () => import('./components/GunLibraryComponent.vue'));
Vue.component('plug-list-component', () => import('./components/PlugLibraryComponent.vue'));
Vue.component('ticket-status-component', () => import('./components/TicketStatus/TicketStatusComponent.vue'));
Vue.component('pad-info-component', () => import('./components/PadInfoComponent.vue'));

Vue.component('customizable-dashboard-component', () => import('./components/CustomizableDashboardComponent.vue'));
Vue.component('display-item-component', () => import('./components/DisplayItemComponent.vue'));

Vue.component('streaming-dashboard-component', () => import('./components/StreamingDashboardComponent.vue'));
Vue.component('well-small-component', () => import('./components/WellSmallComponent.vue'));
Vue.component('site-status-component', () => import('./components/SiteStatusComponent.vue'));
Vue.component('dashboard-wireline-component', () => import('./components/DashboardWirelineComponent.vue'));
Vue.component('handshake-table-component', () => import('./components/HandshakeTableComponent.vue'));
Vue.component('event-table-component', () => import('./components/EventTableComponent.vue')); //widget
Vue.component('whiteboard-message-component', () => import('./components/WhiteboardMessageComponent.vue'));
Vue.component('radial-gauge-component', () => import('./components/RadialGaugeComponent.vue'));
Vue.component('current-value-component', () => import('./components/CurrentValueComponent.vue'));
Vue.component('user-settings-component', () => import('./components/UserSettingsComponent.vue'));
Vue.component('edit-user-profile', () => import('./components/EditUserProfile.vue'));
Vue.component('dashboard-and-api-permission', () => import('./components/DashboardAndAPIPermission.vue'));
Vue.component('graph-with-buffer-component', () => import('./components/GraphWithBufferComponent.vue'));
Vue.component('graph-no-buffer-component', () => import('./components/GraphNoBufferComponent.vue'));
Vue.component('job-info-header', () => import('./components/JobInfoHeader.vue'));
Vue.component('select-component', () => import('./components/SelectComponent.vue'));
Vue.component('plug-gun-status-component', () => import('./components/PlugGunStatusComponent.vue'));
Vue.component('shot-plug-status-row', () => import('./components/ShotPlugStatusRow.vue'));
Vue.component('log-panel', () => import('./components/LogPanel.vue'));
Vue.component('auth-fail-component', () => import('./components/AuthFailComponent.vue'));
Vue.component('key-component', () => import('./components/KeyComponent.vue'));
Vue.component('keyboard-component', () => import('./components/KeyboardComponent.vue'));
Vue.component('login-component', () => import('./components/LoginComponent.vue'));
Vue.component('well-setup-cloud-component', () => import('./components/WellSetupCloudComponent.vue'));


Vue.component('kpi-display-item-old', () => import('./components/KPIDisplayItem.vue')); //widget
Vue.component('kpi-display-item', () => import('./components/DashboardComponents/KpiItems/KpiDisplayItem.vue')); //widget

Vue.component('activity-breakdown-pie-charts', () => import('./components/ActivityBreakdownPieCharts.vue'));
Vue.component('time-interval-select', () => import('./components/TimeIntervalSelect.vue'));
Vue.component('user-profile-component', () => import('./components/UserProfileComponent.vue'));
Vue.component('valve-component', () => import('./components/ValveComponent.vue'));
Vue.component('api-token-enabler', () => import('./components/UserWidgets/ApiTokenEnabler.vue'));
Vue.component('lock-button', () => import('./components/LockButton.vue'));
Vue.component('wireline-diameter-component', () => import('./components/WirelineDiameterComponent')); //widget
Vue.component('wireline-controls-component', () => import('./components/WirelineControlsComponent')); //widget
Vue.component('process-log-page', () => import('./components/ProcessLogs/ProcessLogPage.vue'));

// Comms
Vue.component('comms-widget', () => import('./components/Comms/CommsWidget.vue'));

//Utilities
Vue.component('modified-input', () => import('./components/ModifiedInput.vue'));
Vue.component('calendar', () => import('./components/CalendarComponent.vue'));
Vue.component('dropdown', () => import('./components/DropdownComponent.vue'));
Vue.component('custom-date-time', () => import('./components/CustomDateTime.vue'));

//Lighting Chart
Vue.component('lightning-chart', () => import('./components/LightningChart.vue'));
Vue.component('well-comparison-lightning-chart', () => import('./components/WellComparisonLightningChart'));
Vue.component('heat-map-lightning-chart', () => import('./components/HeatMapsLightningChart'));
Vue.component('stage-comparison-settings-modal', () => import('./components/StageComparisonSettingsModalComponent.vue'));
Vue.component('analytic-options-modal', () => import('./components/Analytics/AnalyticOptionsModal.vue'));
Vue.component('user-analysis-defaults-modal', () => import('./components/UserAnalysisDefaultsModal.vue'));

//Errors Modal
Vue.component('errors-modal', () => import('./components/ErrorsModal.vue'));

// Generic
Vue.component('checkbox-list', () => import('./components/CheckboxList.vue'));
Vue.component('checkbox-list-sectioned', () => import('./components/CheckboxListSectioned.vue'));

//Chart Config Modals
Vue.component('chart-config-modal', () => import('./components/ChartConfigModal.vue'));

//Support Modal
Vue.component('support-modal', () => import('./components/SupportModal.vue'));

// Chat Widget
Vue.component('omnichannel-chat-widget', () => import('./components/OmnichannelChatWidget'));

//Signal-R Disconnect Modal
Vue.component('signalr-error-modal', () => import('./components/SignalRErrorModal.vue'));

// Custom Dashboard Components
Vue.component('wellbore-diagram-component', () => import('./components/WellboreDiagramComponent.vue')); //widget

Vue.component('dashboard-management-component', () => import('./components/DashboardManagementComponent.vue'));

Vue.component('dashboard-comparison-chart', () => import('./components/DashboardComponents/DashboardComparisonChart.vue')); //widget
Vue.component('well-comparison-chart', () => import('./components/MultiWellTimeLineChart.vue')); //widget
Vue.component('stage-summary-heat-map', () => import('./components/StageSummaryHeatMap.vue'));

Vue.component('contractor-library-component', () => import('./components/ContractorLibraryComponent.vue'));
Vue.component('users-list-component', () => import('./components/UsersListComponent.vue'));
Vue.component('chart-comments-marker', () => import('./components/ChartComments/ChartCommentMarker.vue'));
Vue.component('chart-comments-create', () => import('./components/ChartComments/ChartCommentCreate.vue'));
Vue.component('chart-comments-oob-list', () => import('./components/ChartComments/ChartCommentOOBList.vue'));

Vue.component('wireline-activity', () => import('./components/ActivitySVGs/WirelineActivity.vue'));
Vue.component('frac-activity', () => import('./components/ActivitySVGs/FracActivity.vue'));
Vue.component('maintenance-activity', () => import('./components/ActivitySVGs/MaintenanceActivity.vue'));
Vue.component('continuous-frac-to-activity', () => import('./components/ActivitySVGs/ContinuousFracToActivity.vue'));

// Tooltip components
Vue.component('tooltip-component', () => import('./components/TooltipComponent.vue'));
Vue.component('tooltips-list-component', () => import('./components/TooltipsListComponent.vue'));

// Alert Banner Components
Vue.component('alert-banner-management', () => import('./components/AlertBannerManagement.vue'));

//Vue-Multi-Select-Template
Vue.component('multi-select', () => import('./components/MultiSelectComponent.vue'));
Vue.component('multi-select-checkbox', () => import('./components/MultiSelectCheckboxComponent.vue'));
//Alert Modal Components
Vue.component('critical-alert-modal-component', () => import('./components/CriticalAlertModalComponent.vue'));
//Tag Summary Components
Vue.component('tag-summary-component',() => import('./components/TagSummary/TagSummaryComponent.vue'));
Vue.component('tag-summary-table-slot',() => import('./components/TagSummary/TagSummaryTableSlot.vue'));

Vue.component('remote-control-component',() => import('./components/RemoteControlComponent.vue'));

// Tag Summary Table Config
Vue.component('tag-summary-config', () => import('./components/TagSummaryTableConfigModal.vue'));

//Time Keeper Components
Vue.component('time-keeper-component',() => import('./components/TimeKeeper/TimeKeeperComponent.vue'));

//B-Table Components
Vue.component('b-table-export-component', () => import('./components/BTableExportComponent.vue'));

// Company Jobs Select component
Vue.component('select-job-component', () => import('./components/SelectJobComponent.vue'));

//Stage Summary Component
Vue.component('stage-summary-component', () => import('./components/StageSummaryComponent.vue'));

//Form Components
Vue.component('generic-form', () => import('./components/GenericForm.vue'));
Vue.component('edit-user-form', () => import('./components/EditUserForm.vue'));
Vue.component('checkbox-list', () => import('./components/CheckboxList.vue'));

// Remote Data Components
Vue.component('sync-remote-job', () => import('./components/SyncRemoteJob.vue'));
Vue.component('copy-remote-data', () => import('./components/CopyRemoteDataComponent.vue'));

// Camera
Vue.component('camera-viewer-multi-component', () => import('./components/Cameras/CameraViewerMultiComponent.vue'));
Vue.component('camera-single-viewer', () => import('./components/Cameras/CameraSingleViewer.vue'));
Vue.component('camera-clips-viewer', () => import('./components/Cameras/CameraClipsViewer.vue'));
Vue.component('camera-reporting-viewer', () => import('./components/Cameras/CameraReportingViewer.vue'));
Vue.component('settings-device-list', () => import('./components/Cameras/SettingsDeviceList.vue'));
Vue.component('video-player', () => import('./components/Cameras/VideoPlayer.vue'));
Vue.component('video-scrubber', () => import('./components/Cameras/VideoScrubber.vue'));
Vue.component('camera-button', () => import('./components/Cameras/CameraButton.vue'));
Vue.component('camera-navigation', () => import('./components/Cameras/CameraNavigation.vue'));

// comms
Vue.component('push-to-talk', () => import('./components/PushToTalk.vue'));


// Icons Components
Vue.component('import-icon', () => import('./components/Icons/ImportIcon.vue'));
Vue.component('export-icon', () => import('./components/Icons/ExportIcon.vue'));

//Valve Report
Vue.component('valve-report-component', () => import('./components/ValveReportComponent.vue'));