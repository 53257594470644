import Vue from 'vue'; // es6 syntax
 
import moment from 'moment';
import VueMqtt from 'vue-mqtt';

window.Vue = Vue;
 
require('./bootstrap');
require('./components.js');

window.systemMode = process.env.MIX_APP_TYPE;
window.setCookie = function(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

window.getCookie = function(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

window.deleteCookie = function(cname) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
};

const app = new Vue({
    el: '#app',
    created() {
        if (getCookie('activeCustomDashboard') == '') {
            const currentUnixTimeStamp = moment().unix();
            setCookie('activeCustomDashboard', 'false', currentUnixTimeStamp + (10 * 365 * 24 * 60 * 60)); //setting expire date 10 years from now
        }

        if (process.env.MIX_APP_TYPE == 'local') {
            if (getCookie('darkMode') == '')
                setCookie('darkMode', 'false', 14);
            Vue.use(VueMqtt, 'mqtt://' + process.env.MIX_MQTT_BROKER + ':9001', { clientId: 'WebClient-' + parseInt(Math.random() * 100000) });

            this.local = true;
        } else if (window.getCookie('darkMode') == '') {
            window.setCookie('darkMode', 'true', 14);
        }

        this.darkMode = window.getCookie('darkMode') == 'true';

        let fileCss = '/css/bootstrapDark.min.css';
        let fileLogo = '/images/inVision_rebrand_logo_2021.png';
        if (getCookie('darkMode') != 'true') {
            fileCss = '/css/bootstrap.min.css';
            fileLogo = '/images/inVision-Logo-RGB_light_theme.png';
        }
        $('#imgLogo').attr('src', fileLogo);
        $('#bootstrapCss').attr('href', fileCss);

        Vue.config.productionTip = false;
    },
    data: {
        local: false,
        darkMode: '',
        version: process.env.MIX_VERSION_NUM
    }
});

export default app;

