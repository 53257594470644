<template>
<iws-modal
    title="Job Site Weather Details"
    :show-modal="showSevereWeather"
    max-width="900px"
    max-height="90vh"
    :secondary-button-visible="false"
    primary-button-text="Close"
    @close="showSevereWeather = false"
    @primary-action="showSevereWeather = false"
>
    <template #content>
        <div style="text-align: center;">
            <div>
                <span style="font-size: 84px">
                    {{ Math.round(weather.currentConditions.temperature.value) }}&deg;
                </span>
                <span style="font-size: 42px">
                    {{ weather.currentConditions.temperature.unit }}
                </span>
            </div>

            <div class="label-text-size">
                <img :src="grabWeatherIcon(weather.currentConditions.iconCode)" style="height: 38px" />
                {{ weather.currentConditions.phrase }}
            </div>

            <div class="body-text-size">
                {{ $parent.localTime }}
            </div>
        </div>

        <div id="current-conditions-row" class="row">
            <div class="col">
                <div class="label-text-size-reduced">
                    {{ weather.currentConditions.precipitationSummary.pastHour.value }}{{ weather.currentConditions.precipitationSummary.pastHour.unit }}
                </div>

                <div class="body-text-size-reduced">
                    Precipitation
                </div>
            </div>

            <div class="col">
                <div class="label-text-size-reduced">
                    {{ Math.round(weather.currentConditions.wind.speed.value) }}{{ user.weather_unit === 0 ? 'mph' : 'km/h' }} {{ weather.currentConditions.wind.direction.localizedDescription }}
                </div>

                <div class="body-text-size-reduced">
                    Wind
                </div>
            </div>

            <div class="col">
                <div class="label-text-size-reduced">
                    {{ weather.currentConditions.visibility.value }}{{ weather.currentConditions.visibility.unit }}
                </div>

                <div class="body-text-size-reduced">
                    Visibility
                </div>
            </div>
        </div>

        <div v-if="!('forecast' in weather)" style="text-align: center;">
            <div class="spinner-border spinner-border loading-spinner-position" role="status">
                <span class="sr-only mb-5">Loading...</span>
            </div>
        </div>
        <div v-else-if="weather.forecast && !!weather.forecast.length" id="forecast-row" class="row">
            <div v-for="forecastWeather in weather.forecast" :key="`forecast${forecastWeather.date}`" class="col" data-toggle="tooltip" :title="forecastWeather.iconPhrase">
                <img :src="grabWeatherIcon(forecastWeather.iconCode)" />

                <span class="label-text-size-reduced">
                    {{ Math.round(forecastWeather.temperature.value) }}&deg;
                </span>
                <span style="font-size: 14px;">
                    {{ forecastWeather.temperature.unit }}
                </span>

                <div class="body-text-size-reduced">
                    {{ _applyTimeOffset(forecastWeather.date) }}
                </div>
            </div>
        </div>

        <div v-for="weather in weather.severeWeather" class="severe-weather-details">
            <div class="label-text-size">
                {{ weather.description.english }}
            </div>

            <div v-for="alert in weather.alertAreas" class="body-text-size">
                <div>
                    {{ alert.name }}

                    <span class="float-right">
                        {{ _applyTimeOffset(alert.startTime) }}
                    </span>
                </div>

                {{ alert.summary }}
            </div>
        </div>
    </template>

    <template #footer-secondary-action>
        <span style="font-size: 12px; position: absolute; left: 1rem;">
            Last updated {{ _fromNow(weather.date) }} ago via <a href="https://www.accuweather.com/" target="_blank">AccuWeather</a>
        </span>
    </template>
</iws-modal>
</template>

<script>
import DateFunctions from '../DateFunctions.js';
const { applyTimeOffset, fromNow } = DateFunctions;

export default {
    props: {
        weather: {
            type: Object,
            required: true
        },
        job: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        showSevereWeather: false,
    }),

    methods: {
        _applyTimeOffset: function(val=new Date()) {
            return applyTimeOffset(val, this.job.hourOffset, this.user.clock_format === 0 ? 'h:mm A' : 'HH:mm');
        },
        _fromNow: function(val=new Date()) {
            return fromNow(val);
        },
        grabWeatherIcon: function(iconCode) {
            return `/images/weather-icons/${iconCode}.png`;
        },

        open: function() {
            this.showSevereWeather = true;

            axios.get(`/${this.job.jobNumber}/weather/forecast`).then(_weather => {
                this.$set(this.weather, 'forecast', Object.values(_weather.data));
            });
        }
    }
}
</script>

<style scoped>
    #current-conditions-row {
        text-align: center;
        margin: 30px;
        padding: 15px;
    }
    #current-conditions-row>.col:not(:first-of-type) {
        border-left: 1px solid var(--form-color);
    }

    #forecast-row {
        text-align: center;
        margin: 30px;
        padding: 15px;
        background-color: rgba(52, 58, 64, 0.5);
        border: 1px solid var(--form-color);
        border-radius: 5px;
    }
    #forecast-row .col:not(:first-of-type) {
        border-left: 1px solid var(--form-color);
    }
    #forecast-row .col img {
        position: relative;
        top: -4px;

        height: 30px;
    }

    .severe-weather-details {
        padding: 25px 15px;
    }
    .severe-weather-details .body-text-size {
        margin-top: 15px;
    }
</style>
