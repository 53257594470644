import Vue from 'vue';
 
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, getActiveToasts } = GlobalFunctions;

const dashboardAlertStore = Vue.observable({
    accessKey: null,            // Store the LocalStorage key for later use (gets rebuilt every refresh anyway)
    tagThresholdMap: {},
    alertingTags: [],

    processedTelemetryData: {}  // Stores the previously processed values to skip rechecking if nothing changes
});


// LocalStorage key is specific for each user + job combo
export function buildDashAlertsLsKey(user, job) {
    if (!user || !job)
        throw Error('Params not provided');
    
    dashboardAlertStore.accessKey = `dash_alerts_${user.id}_${job.id}`;
    return dashboardAlertStore.accessKey;
}

export function fetchThresholdMap() {
    if (!dashboardAlertStore.accessKey)
        throw Error('Key not set');

    // Grab from LS, add setters / getters + not saved values
    const map = JSON.parse(localStorage.getItem(dashboardAlertStore.accessKey) || '{}');
    Object.keys(map).forEach(_key => {
        Vue.set(map[_key], 'friendlyTagname', map[_key]?.friendlyTagname);
        Vue.set(map[_key], 'min', map[_key]?.min);
        Vue.set(map[_key], 'max', map[_key]?.max);
        Vue.set(map[_key], 'withinThreshold', true);
        Vue.set(map[_key], 'acknowledged', map[_key]?.acknowledged || false);
    })

    dashboardAlertStore.tagThresholdMap = map;
    return dashboardAlertStore.tagThresholdMap;
}

export function storeThresholdMap(tagThresholdMap) {
    if (!dashboardAlertStore.accessKey)
        throw Error('Key not set');

    // Only store specific values (remove setters / getters and withinThreshold status)
    const map = {};
    Object.keys(tagThresholdMap).forEach(_key => {
        map[_key] = {
            friendlyTagname: tagThresholdMap[_key]?.friendlyTagname,
            min: tagThresholdMap[_key]?.min,
            max: tagThresholdMap[_key]?.max,
            acknowledged: tagThresholdMap[_key]?.acknowledged
        }
    });

    // If no tags just delete / do not store (we default to empty list anyway)
    if (!isNullOrEmpty(Object.keys(map))) {
        localStorage.setItem(dashboardAlertStore.accessKey, JSON.stringify(map));
    } else {
        localStorage.removeItem(dashboardAlertStore.accessKey);
    }
    
    // Update frontend state
    dashboardAlertStore.tagThresholdMap = tagThresholdMap || {};
    dashboardAlertStore.processedTelemetryData = {};
}

export function processTelemetryUpdate(telemetry) {  
    let alertingTags = []; 

    Object.keys(dashboardAlertStore.tagThresholdMap).forEach(_key => {
        // Go through each saved alert processing every requested tag with incoming data
        if (_key in telemetry) {
            let value = telemetry[_key];                                        // The new, incoming value
            let lastValue = dashboardAlertStore.processedTelemetryData[_key];   // The last processed value

            // Maybe a temp feature but if we're getting the match from wireline, it is expected to get a list
            // Use the first non null value in the list
            if (Array.isArray(value))
                value = value.find(_value => _value !== null);

            // For whatever reason some values are booleans and such (Just ignore these ones)
            // If there is a stored last value and it matches the current one, nothing has changed so we can just skip the tests
            if (!isNaN(value) && (isFalsy(lastValue) || lastValue !== value)) {
                value = Math.round(value);
                const alert = dashboardAlertStore.tagThresholdMap[_key];
                const { min, max } = alert;
                const underMin = !isFalsy(min) && value < min;
                const overMax = !isFalsy(max) && value > max;
                const withinThreshold = !(underMin || overMax);

                if (withinThreshold) {
                    // If we are in a valid state, just always clear the acknowledgement to make sure
                    dashboardAlertStore.tagThresholdMap[_key].acknowledged = false;
                } else if (alert?.withinThreshold && !alert?.acknowledged) {
                    // Only show toast if we are leaving threshold (current value is false but saved value is true) and the alert is not already acknowledged
                    const id = `dash_alert_${_key}`;

                    // Only show if an alert for this tag isn't already rendered
                    if (!(id in getActiveToasts()))
                        GlobalFunctions.alertModal({
                            id,
                            title: 'Allowed threshold exceeded',
                            body: [
                                `${alert.friendlyTagname} (${_key}).`,
                                `${value} is ${underMin ? `under the allowed min of ${min}` : `over the allowed max of ${max}`}.`
                            ],
                            variant: 'danger',
                            confirm: 'Acknowledge',
                            onConfirm: () => acknowledgeTag(_key),
                            class: 'dash-alert',
                            noCloseButton: false
                        });
                }
        
                Vue.set(dashboardAlertStore.tagThresholdMap[_key], 'withinThreshold', withinThreshold);
    
                Vue.set(dashboardAlertStore.processedTelemetryData, _key, value);   
            }
        }

        // After being processed, if it is not within threshold we add it to our list of alerting tags (for easy access)
        if (!dashboardAlertStore.tagThresholdMap[_key]?.withinThreshold && !dashboardAlertStore.tagThresholdMap[_key]?.acknowledged)
            alertingTags.push(dashboardAlertStore.tagThresholdMap[_key]);
    });

    dashboardAlertStore.alertingTags = alertingTags;
}

export function initDashAlerts(user, job) {
    buildDashAlertsLsKey(user, job);
    fetchThresholdMap();
}

export function acknowledgeTag(name) {
    dashboardAlertStore.tagThresholdMap[name].acknowledged = true;
    storeThresholdMap(dashboardAlertStore.tagThresholdMap);
}

export function isTagAlerting(name) {
    if (name in dashboardAlertStore.tagThresholdMap)
        return !dashboardAlertStore.tagThresholdMap[name]?.withinThreshold && !dashboardAlertStore.tagThresholdMap[name]?.acknowledged;
    return false;
}
export function isTagAcknowledged(name) {
    if (name in dashboardAlertStore.tagThresholdMap)
        return !dashboardAlertStore.tagThresholdMap[name]?.withinThreshold && dashboardAlertStore.tagThresholdMap[name]?.acknowledged;
    return false;
}

export default dashboardAlertStore;