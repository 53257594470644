<template>
    <iws-modal
        :showModal="showModal"
        title="Dashboard Alerts"
        id="dashboard-alerts-modal"
        max-width="1000px"
        @close="close"
        @secondary-action="close"
        @primary-action="saveChanges"

        :show-backdrop="false"
        :enable-click-outside="false"
        :close-on-clickaway="false"
        :can-click-outside-components="true"
        
        :draggable="true"
        :top.sync="modalPositionTop"
        :left.sync="modalPositionLeft"
    >
        <template #content>
            <div class="secondary-text-color" style="font-size: 18px; margin-bottom: 0.75em">
                Please ensure your system sound is set to receive audible alerts
            </div>

            <iws-select
                id="tag-search-select"
                label="Source Tag Name"
                placeholder="Add tags to your alerts by tag name"
                :options="filteredTags"
                display-name="fullName"
                clearOnSelect
                @change="onTagSelected"
            />

            <div v-if="_isNullOrEmpty(tagsAsList)" id="no-alerts">
                <div class="label-text-size">
                    No Alerts
                </div>
                <div class="body-text-size">
                    Select a tag above to add min and max threshold alerts. <br>
                    You will be alerted when the tag is <b>outside</b> the set threshold range.
                </div>
            </div>
            <div v-else id="alerts-container">
                <iws-table :columns="tableCols" :items="tagsAsList" sortByCol="name">
                    <template #cell_name="{ data }">
                        <span :class="{ 'danger-text-color': isAlerting(data.item.name), 'warning-text-color': isAcknowledged(data.item.name) }">
                            <div class="label-text-size-reduced">
                                {{ data.item.friendlyTagname }}
                            </div>
                            <div class="body-text-size-reduced">
                                {{ data.item.name }}
                            </div>
                        </span>
                    </template>

                    <template #cell_value="{ data }">
                        <span :class="{ 'danger-text-color': isAlerting(data.item.name), 'warning-text-color': isAcknowledged(data.item.name) }">
                            {{ getValue(data.item.name) }}
                        </span>
                    </template>

                    <template #cell_min="{ data }">
                        <iws-input v-if="tagThresholdMap[data.item.name]"
                            :value.sync="tagThresholdMap[data.item.name].min"
                            type="number"
                            :validator="() => inputValidator(tagThresholdMap[data.item.name])"
                            errorMessage="Min or max is required"
                            :id="`minInput_${data.item.name}`"
                            :ref="`minInput_${data.item.name}`"
                            style="max-width: 100px"
                        />
                    </template>

                    <template #cell_max="{ data }">
                        <iws-input v-if="tagThresholdMap[data.item.name]"
                            :value.sync="tagThresholdMap[data.item.name].max"
                            type="number"
                            :validator="() => inputValidator(tagThresholdMap[data.item.name])"
                            errorMessage="Min or max is required"
                            :id="`maxInput_${data.item.name}`"
                            :ref="`maxInput_${data.item.name}`"
                            style="max-width: 100px"
                        />
                    </template>
                    
                    <template #cell_actions="{ data }">
                        <iws-button
                            :id="`removeTag_${data.item.name}`"
                            type="outline-danger"
                            icon="fas fa-trash-alt"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Delete Alert"
                            class="mr-1"
                            @click="removeTag(data.item.name)"
                        />
                        <iws-button v-if="isAlerting(data.item.name)"
                            :id="`acknowledgeTag_${data.item.name}`"
                            type="outline-success"
                            icon="fa fa-check"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Acknowledge Alert"
                            @click="_acknowledgeTag(data.item.name)"
                        />
                    </template>
                </iws-table>
            </div>
        </template>
        
        <template #footer-secondary-action v-if="showModal && !_isNullOrEmpty(tagsAsList)">
            <iws-button
                :text="`Acknowledge All (${tagsInError.length || 0})`"
                type="outline-success"
                class="position-absolute"
                style="left: 1rem"
                :disabled="!containsErrorState"
                :click="acknowledgeAll"
            />
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, iwsConfirm, toast, compareObjects } = GlobalFunctions;

import dashboardAlertStore, { storeThresholdMap, acknowledgeTag, isTagAlerting, isTagAcknowledged, processTelemetryUpdate } from '../../store/dashboardAlerts.js'
import eventBus from '../../eventBus';

export default {
    props: {
        tags: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        showModal: false,
        tagThresholdMap: null,
        initTagThresholdMap: null,  // Store a copy of the initial map for comparing changes / discarding

        telemetryData: {},
        tableCols: [
            {
                key: 'name',
                label: 'Name'
            }, {
                key: 'value',
                label: 'Value',
                sortable: false
            }, {
                key: 'min',
                label: 'Minimum',
                sortable: false
            }, {
                key: 'max',
                label: 'Maximum',
                sortable: false
            }, {
                label: null,
                key: 'actions',
                showHeader: false,
                sortable: false
            }
        ],

        modalPositionTop: 20,
        modalPositionLeft: document.body.clientWidth - 1030
    }),

    computed: {
        filteredTags: function() {
            // Only show tags not being used
            return this.tags?.filter(_tag => !(_tag.name in this.tagThresholdMap))?.map(_tag => ({
                ..._tag,
                fullName: `${_tag?.friendlyTagname || _tag.name} (${_tag.name})`
            })) || [];
        },

        tagsAsList: function() {
            return Object.keys(this.tagThresholdMap).map(_key => ({
                name: _key,
                ...this.tagThresholdMap[_key]
            }));
        },

        tagsInError: function() {
            return dashboardAlertStore.alertingTags || [];
        },
        containsErrorState: function() {
            return !isNullOrEmpty(this.tagsInError);
        }
    },

    methods: {
        _isFalsy: (val) => isFalsy(val),
        _isNullOrEmpty: (val) => isNullOrEmpty(val),

        inputValidator: function(tag) {
            // If neither are set, fail
            if (isFalsy(tag.min) && isFalsy(tag.max))
                return false;
            // If just one is set, allow it
            if (isFalsy(tag.min) ^ isFalsy(tag.max))
                return true;
            // If both are set, make sure min is smaller than the max
            return tag.min < tag.max;
        },

        getValue: function(name) {
            return !isNaN(this.telemetryData[name]) ? Math.round(this.telemetryData[name]) : 'N/A';
        },

        isAlerting: function(name) {
            return isTagAlerting(name);
        },
        isAcknowledged: function(name) {
            return isTagAcknowledged(name);
        },
        _acknowledgeTag: function(name) {
            // Update the local state (including the initMap as this doesn't count for discarding)
            this.tagThresholdMap[name].acknowledged = true;
            this.initTagThresholdMap[name].acknowledged = true;

            // Update the saved state for acknowledgements even if they don't hit save
            acknowledgeTag(name);
        },

        removeTag: function(name) {
            this.$delete(this.tagThresholdMap, name);
        },
        onTagSelected: function(tag) {
            let tagDetails = {};
            this.$set(tagDetails, 'friendlyTagname', tag.friendlyTagname);
            this.$set(tagDetails, 'min', null);
            this.$set(tagDetails, 'max', null);
            this.$set(tagDetails, 'withinThreshold', true);
            this.$set(tagDetails, 'acknowledged', false);
            
            this.$set(this.tagThresholdMap, tag.name, tagDetails);
        },
        validateTag: function(name) {
            // To pass validation, at least one of the two values must be set
            // We want to touch both to show errors on both if none at set
            const results = [ this.$refs[`minInput_${name}`]?.validateInput(), this.$refs[`maxInput_${name}`]?.validateInput() ];
            return results[0] || results[1];
        },
        validateMap: function() {
            return isNullOrEmpty(this.tagsAsList.filter(_tag => !this.validateTag(_tag.name)));
        },

        acknowledgeAll: function() {
            this.tagsAsList.forEach(_tag => this._acknowledgeTag(_tag.name));
        },

        open: function() {
            this.tagThresholdMap = _.cloneDeep(dashboardAlertStore.tagThresholdMap);      // Store a clone of the map for editing discardable changes
            this.initTagThresholdMap = _.cloneDeep(dashboardAlertStore.tagThresholdMap);  // Store a copy to compare unsaved changes
            this.showModal = true;
        },
        close: async function() {
            // if changes were made, confirm before discarding
            if (!compareObjects(this.tagThresholdMap, this.initTagThresholdMap)) {
                const _res = await iwsConfirm({
                    title: 'Are you sure?',
                    body: `Your unsaved changes will be lost if you cancel now`,
                    confirmColour: 'danger',
                    width: '355px'
                });

                if (!_res)
                    return;
            }

            this.showModal = false;
            this.tagThresholdMap = null;
            this.initTagThresholdMap = null;
        },

        saveChanges: function() {
            if (!this.validateMap())
                return toast({
                    title: 'Every tag must have at least a minimum or maximum set',
                    variant: 'danger'
                });
            
            storeThresholdMap(this.tagThresholdMap);
            this.showModal = false;
            this.tagThresholdMap = null;
            this.initTagThresholdMap = null;
        }
    },

    created() {
        // TODO: This signal is emitted by customizableDashboardComponent.signalrMessageHandler()
        // This therefor is dependant on us being on the dashboard, ideally this would eventually work on any job page (planned for a later phase)
        eventBus.$on('signalr-updated', message => {
            if (!isFalsy(message?.telemetry)) {
                // Store telemetry data for value lookup in table
                this.telemetryData = { ...this.telemetryData, ...message.telemetry };

                if (!isFalsy(message?.wireline))
                    this.telemetryData = { ...this.telemetryData, ...message.wireline };

                // Prcoess threshold values
                processTelemetryUpdate(this.telemetryData);
            }
        })
    }
};
</script>

<style>
    #dashboard-alerts-modal .modal-container {
        box-shadow: rgba(0, 0, 0, 0.788) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
    #dashboard-alerts-modal table {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    #dashboard-alerts-modal table thead {
        top: -1.5em;
    }
    #dashboard-alerts-modal .modal-title {
        cursor:move;
        user-select: none; /* Prevent text selection */
    }
</style>
<style scoped>
    #no-alerts {
        padding: 10px 15px;
        border: 1px solid #95989A;
        border-radius: 5px;
    }
    #no-alerts,
    #alerts-container {
        margin: 1.5em 0px 0px;
    }

    .tag-row {
        margin: 20px 0px;
    }
    
    .tag-name {
        display: table-cell;
        vertical-align: bottom;
        height: 67px;
    }
</style>