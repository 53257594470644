<template>
    <div>
        <div v-for="(alert, index) in publishedAlerts">
            <div v-if="alert.publish && (!('display' in alert) || alert.display)" :class="[isHighSeverity(alert) ? 'alert-danger' : isMediumSeverity(alert) ? 'alert-warning' : 'alert-success']" class="alert alert-dismissible text-center alert-banner" role="alert">
                <i :class="[isHighSeverity(alert) ? 'fas fa-exclamation-triangle' : isMediumSeverity(alert) ? 'fas fa-exclamation-circle' : 'fas fa-info-circle']" style="font-size:15px;"></i>
                <span style="font-size:15px;">{{ alert.message }}</span>
                <button type="button" class="close alert-banner" data-dismiss="alert" aria-label="Close" @click="closeAlert(alert, index)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import JSONLocalStorageMixin from "../mixins/JSONLocalStorageMixin";
export default {
    mixins: [JSONLocalStorageMixin],
    props: {
        user: {
            type: Object,
            default: null,
            required: false
        },
    },
    data() {
        return {
            alertInterval: null,
            publishedAlerts: null,
            savedJsonLocalStorage: null,
        }
    },
    async created() {
        await this.setPublishedAlert();
        this.alertInterval = setInterval(async () => await this.setPublishedAlert(), 60000)
    },
    mounted() {

    },
    unmounted() {
        clearInterval(this.alertInterval);
    },
    methods: {
        async setPublishedAlert() {
            this.publishedAlerts = await this.getAlerts();
            this.jsonLocalStorage.key = this.user.username + '.published-alerts';
            this.loadJsonLocalStorage();
            this.updateJsonLocalStorage();
            this.loadDisplaySettings();

        },
        async getAlerts() {
            return await axios({
                url: '/alertBanner/getAlerts',
                method: 'GET'
            })
            .then(res => res.data)
            .catch(e => console.error('error retrieving alerts list', e))
        },
        loadJsonLocalStorage(){
            let loadedJsonStorageData = this.jsonLocalStorage.load(this.jsonLocalStorage.key);
            this.savedJsonLocalStorage = loadedJsonStorageData.publishedAlerts;
        },
        closeAlert(alert, index){
            this.publishedAlerts[index].display = false;
            this.savedJsonLocalStorage.find(alert => alert.id == this.publishedAlerts[index].id).display = false;
            this.jsonLocalStorage.publishedAlerts = this.savedJsonLocalStorage;
            this.jsonLocalStorage.save();
        },
        updateJsonLocalStorage(){
            //If alert list already exists in local storage
            if(this.savedJsonLocalStorage){
                let updatedAlertList = this.savedJsonLocalStorage;
                for(let i=0; i<this.publishedAlerts.length; i++){
                    let alertAlreadyExists = null;
                    alertAlreadyExists = this.savedJsonLocalStorage.find(alert => alert.id == this.publishedAlerts[i].id);
                    if(!alertAlreadyExists){
                        updatedAlertList.push(this.publishedAlerts[i]);
                    }else{
                        this.publishedAlerts[i].display = alertAlreadyExists.display;
                    }
                }
                //sync local storage with published alerts
                for(let i=0; i<updatedAlertList.length; i++){
                    let alertStillExists = null;
                    alertStillExists = this.publishedAlerts.find(alert => alert.id == updatedAlertList[i].id);
                    if(!alertStillExists){
                        updatedAlertList.splice(i, 1);
                    }
                }
                this.jsonLocalStorage.publishedAlerts = updatedAlertList;
                this.jsonLocalStorage.save();
            //Else if alert list does not exist in local storage
            } else {
                this.jsonLocalStorage.publishedAlerts = this.publishedAlerts;
                this.jsonLocalStorage.save();
            }
            this.loadJsonLocalStorage();
        },
        loadDisplaySettings(){
            for(let i=0; i<this.publishedAlerts.length; i++){
                let alertDisplaySetting = null;
                alertDisplaySetting = this.savedJsonLocalStorage.find(alert => alert.id == this.publishedAlerts[i].id);
                if(alertDisplaySetting) {
                    this.publishedAlerts[i].display = alertDisplaySetting.display;
                }
            }
        },
        isHighSeverity(alert){
            return(alert.severity == 'high');
        },
        isMediumSeverity(alert){
            return(alert.severity == 'medium');
        },
    }
}
</script>

<style scoped>
    .alert,
    .alert-banner.close {
        padding: 5px 1.25rem !important;
    }
    .alert {
        margin-bottom: 0px !important;
        border-radius: 0px !important;
    }
</style>
