var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      showModal: _vm.showModal,
      title: "Dashboard Alerts",
      id: "dashboard-alerts-modal",
      "max-width": "1000px",
      "show-backdrop": false,
      "enable-click-outside": false,
      "close-on-clickaway": false,
      "can-click-outside-components": true,
      draggable: true,
      top: _vm.modalPositionTop,
      left: _vm.modalPositionLeft,
    },
    on: {
      close: _vm.close,
      "secondary-action": _vm.close,
      "primary-action": _vm.saveChanges,
      "update:top": function ($event) {
        _vm.modalPositionTop = $event
      },
      "update:left": function ($event) {
        _vm.modalPositionLeft = $event
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "secondary-text-color",
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "0.75em",
                  },
                },
                [
                  _vm._v(
                    "\n            Please ensure your system sound is set to receive audible alerts\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("iws-select", {
                attrs: {
                  id: "tag-search-select",
                  label: "Source Tag Name",
                  placeholder: "Add tags to your alerts by tag name",
                  options: _vm.filteredTags,
                  "display-name": "fullName",
                  clearOnSelect: "",
                },
                on: { change: _vm.onTagSelected },
              }),
              _vm._v(" "),
              _vm._isNullOrEmpty(_vm.tagsAsList)
                ? _c("div", { attrs: { id: "no-alerts" } }, [
                    _c("div", { staticClass: "label-text-size" }, [
                      _vm._v("\n                No Alerts\n            "),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "body-text-size" }, [
                      _vm._v(
                        "\n                Select a tag above to add min and max threshold alerts. "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                You will be alerted when the tag is "
                      ),
                      _c("b", [_vm._v("outside")]),
                      _vm._v(" the set threshold range.\n            "),
                    ]),
                  ])
                : _c(
                    "div",
                    { attrs: { id: "alerts-container" } },
                    [
                      _c("iws-table", {
                        attrs: {
                          columns: _vm.tableCols,
                          items: _vm.tagsAsList,
                          sortByCol: "name",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell_name",
                            fn: function ({ data }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "danger-text-color": _vm.isAlerting(
                                        data.item.name
                                      ),
                                      "warning-text-color": _vm.isAcknowledged(
                                        data.item.name
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "label-text-size-reduced",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(data.item.friendlyTagname) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "body-text-size-reduced" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(data.item.name) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell_value",
                            fn: function ({ data }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "danger-text-color": _vm.isAlerting(
                                        data.item.name
                                      ),
                                      "warning-text-color": _vm.isAcknowledged(
                                        data.item.name
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.getValue(data.item.name)) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell_min",
                            fn: function ({ data }) {
                              return [
                                _vm.tagThresholdMap[data.item.name]
                                  ? _c("iws-input", {
                                      ref: `minInput_${data.item.name}`,
                                      staticStyle: { "max-width": "100px" },
                                      attrs: {
                                        value:
                                          _vm.tagThresholdMap[data.item.name]
                                            .min,
                                        type: "number",
                                        validator: () =>
                                          _vm.inputValidator(
                                            _vm.tagThresholdMap[data.item.name]
                                          ),
                                        errorMessage: "Min or max is required",
                                        id: `minInput_${data.item.name}`,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.tagThresholdMap[data.item.name],
                                            "min",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell_max",
                            fn: function ({ data }) {
                              return [
                                _vm.tagThresholdMap[data.item.name]
                                  ? _c("iws-input", {
                                      ref: `maxInput_${data.item.name}`,
                                      staticStyle: { "max-width": "100px" },
                                      attrs: {
                                        value:
                                          _vm.tagThresholdMap[data.item.name]
                                            .max,
                                        type: "number",
                                        validator: () =>
                                          _vm.inputValidator(
                                            _vm.tagThresholdMap[data.item.name]
                                          ),
                                        errorMessage: "Min or max is required",
                                        id: `maxInput_${data.item.name}`,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.tagThresholdMap[data.item.name],
                                            "max",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell_actions",
                            fn: function ({ data }) {
                              return [
                                _c("iws-button", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    id: `removeTag_${data.item.name}`,
                                    type: "outline-danger",
                                    icon: "fas fa-trash-alt",
                                    "data-toggle": "tooltip",
                                    "data-placement": "bottom",
                                    title: "Delete Alert",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeTag(data.item.name)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.isAlerting(data.item.name)
                                  ? _c("iws-button", {
                                      attrs: {
                                        id: `acknowledgeTag_${data.item.name}`,
                                        type: "outline-success",
                                        icon: "fa fa-check",
                                        "data-toggle": "tooltip",
                                        "data-placement": "bottom",
                                        title: "Acknowledge Alert",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm._acknowledgeTag(
                                            data.item.name
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
            ]
          },
          proxy: true,
        },
        _vm.showModal && !_vm._isNullOrEmpty(_vm.tagsAsList)
          ? {
              key: "footer-secondary-action",
              fn: function () {
                return [
                  _c("iws-button", {
                    staticClass: "position-absolute",
                    staticStyle: { left: "1rem" },
                    attrs: {
                      text: `Acknowledge All (${_vm.tagsInError.length || 0})`,
                      type: "outline-success",
                      disabled: !_vm.containsErrorState,
                      click: _vm.acknowledgeAll,
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }