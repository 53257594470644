import Vue from 'vue';

import moment from 'moment';
import eventBus from "./eventBus";
import {v4 as uuidv4} from 'uuid';

var _resolveGlobalConfirm;

eventBus.$on('iws-global-confirm-close', res => _resolveGlobalConfirm(res));


let activeToasts={};

var Component = Vue.extend({
    mixins: [{
        methods: {
            alertModal(message) {
                const id = message.id || `alert-modal-${new Date()}`;
                const autoHideDelay = message.autoHideDelay || 15000;

                let body;

                if (typeof message.body == 'string') {
                    body = this.$createElement(
                        'div',
                        message.body
                    );
                } else if (Array.isArray(message.body)) {
                    body = message.body.map(_body => this.$createElement(
                        'div',
                        _body
                    ));
                }

                this.$bvToast.toast([
                    body,
                    this.$createElement(
                        'b-button',
                        {
                            on: { 
                                click: () => {
                                    if (typeof message?.onConfirm == 'function')
                                        message?.onConfirm();
                                    return this.$bvToast.hide(id);
                                } 
                            }
                        },
                        message.confirm || 'Ok'
                    )
                ], {
                    ...message,
                    id,
                    autoHideDelay,
                    toastClass: 'alert-modal '+message.class || '',
                    noCloseButton: 'noCloseButton' in message ? message.noCloseButton : true
                });

                return new Promise((resolve, reject) => {
                    // Track the ids of active toasts for the flush function
                    activeToasts[id] = resolve;
                })
            },
            showToast(message) {
                const id = message.id || `toast-${new Date()}`;
                const autoHideDelay = message.autoHideDelay || 8000;

                // looks weird if an empty body is provided, need to completely delete it from the body to have to not display
                if (message.title == null)
                    delete message.title;
                if (message.body == null)
                    delete message.body;

                this.$bvToast.toast(message.body || ' ', {
                    ...message,
                    id: id,
                    autoHideDelay: autoHideDelay,
                    toastClass: !('body' in message) ? 'no-padding-toast' : null
                });

                return new Promise((resolve, reject) => {
                    // Track the ids of active toasts for the flush function
                    activeToasts[id] = resolve;
                })
            },

            // Hides all active toasts
            // Can instead take a list of toast ids to only hide specific toasts (if for some reason you wanted that)
            flushToasts(ids) {
                if (ids == null)
                    ids = Object.keys(activeToasts);

                ids.forEach(_id => this.$bvToast.hide(_id));
            }
        },

        created() {
            this.$root.$on('bv::toast::hidden', (bvEvent, modalId) => {
                // activeToasts keeps track of all toasts in render
                // We use this list of ids to flush out all toasts in flushToasts
                // This can also be used to resolve the promise to know when each individual toast has been hidden (either automatically or manually)
                if (bvEvent.componentId in activeToasts) {
                    activeToasts[bvEvent.componentId]();
                    delete activeToasts[bvEvent.componentId];
                }
            })
        }
    }]
})

var component = new Component();

class GlobalFunctions {
    onAlertPressLogic(data, jobNumber) {
		if( (typeof data.wellIndex !== 'undefined' || typeof data.wellNumber !== 'undefined') && typeof data.stageNumber !== 'undefined' ) {
			const wellIndex = typeof data.wellIndex !== 'undefined' ? data.wellIndex : data.wellNumber;
			const startStage = data.stageNumber;
			const endStage = data.stageNumber;
			const url =`/wireline-op/${jobNumber}/history/${wellIndex}/${startStage}/${endStage}`;
			window.open(url,'_self');
		}
	}
    analysisChartTypes() {
        const chartTypes = Object.freeze({
            STAGE_COMPARE: Symbol('stage_compare'),
            WELL_COMPARE: Symbol('well_compare'),
            STAGE_SUMMARY_HEATMAP: Symbol('stage_summary_heatmap')
        });
        return chartTypes;
    }
    getCSRFToken() {
        return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }
    async getJobInfo(jobID) {
        const url = '/jobs/' + jobID;
        return await $.get(url);
    }
    async getCurrentActivities(jobID) {
        const url = '/activities/current/'+jobID;
        return await $.get(url);
    }
    async getLatestData(jobID) {
        const url = '/latestData/'+jobID;
        return await $.get(url);
    }

    openSupportModal() {
        const target = $('#supportModal');
        target.modal('show');
    }

    timeAppendLeadingZeros(n) {
        if(n<10) {
            return '0'+n;
        }
        return n;
    }

    convertDateTimeWithOffset(datetimeStr, offset) {
        const date = moment(datetimeStr);
        date.add({hours: offset});
        return date;
    }


    getColorLuminosity(hexCode) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
        const rgb = result && result[1] && result[2] && result[3] ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;

        if(!rgb) {
            //can't determine luminosity
            return null;
        }

        const lrgb = [];
        rgb.forEach(function(c) {
            c = c / 255.0;
            if (c <= 0.03928) {
                c = c / 12.92;
            } else {
                c = Math.pow((c + 0.055) / 1.055, 2.4);
            }
            lrgb.push(c);
        });

		return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
	}
	async getJobInfo(jobID){
			var url = '/jobs/' + jobID;
			return await $.get(url);
	}
	async getCurrentActivities(jobID){
		let url = '/activities/current/'+jobID;
		return await $.get(url);
	}

	timeAppendLeadingZeros(n){
		if(n<10){
				return "0"+n;
		}
		return n;
	}

	convertDateTimeWithOffset(datetimeStr, offset) {
		var date = moment(datetimeStr);
		date.add({hours: offset});
		return date;
	}


	getColorLuminosity(hexCode) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
		var rgb = result && result[1] && result[2] && result[3] ? [
				parseInt(result[1], 16),
				parseInt(result[2], 16),
				parseInt(result[3], 16)
		] : null;

		if(!rgb) {
				//can't determine luminosity
				return null;
		}

		var lrgb = [];
		rgb.forEach(function(c) {
				c = c / 255.0;
				if (c <= 0.03928) {
						c = c / 12.92;
				} else {
						c = Math.pow((c + 0.055) / 1.055, 2.4);
				}
				lrgb.push(c);
		});

		//returns luminosity in first index, raw rgb in second index
		return [0.2126 * lrgb[0] + 0.7152 * lrgb[1] + 0.0722 * lrgb[2], rgb];
	}

    // blend two hex colors together by an amount
    blendColors(colorA, colorB, amount) {
        const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
        const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
        const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0');
        const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0');
        const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0');
        return '#' + r + g + b;
    }

	getTitleColorForBG(hexCode) {
		if(!hexCode)
			return '#ffffff';

		var lum = this.getColorLuminosity(hexCode);
		return !lum || (lum[0] > 0.179) ? '#000000' : '#ffffff';
	}

    getGreyShadeColorForBG(hexCode) {
		if(!hexCode)
			return '#ffffff';

		var lum = this.getColorLuminosity(hexCode);
		return !lum || (lum[0] > 0.179) ? '#282828' : '#d3d3d3';
	}

	getLineColorForWellColor(hexCode) {
		var lum = this.getColorLuminosity(hexCode);
		if(!lum) {
				return '#000000';
		}

        //increasing this value (range between 0 and 1) allows more lighter colors to remain
        const luminosityCutoff = 0.5;
        if(lum[0] < 0.5) {
            return hexCode;
        }

        //darken color by 10%
        const percent = -25;
        const rgb = lum[1];

        const rPercent = parseInt(rgb[0] * (100 + percent) / 100);
        const gPercent = parseInt(rgb[1] * (100 + percent) / 100);
        const bPercent = parseInt(rgb[2] * (100 + percent) / 100);

        const newR = (rPercent<255)?rPercent:255;
        const newG = (gPercent<255)?gPercent:255;
        const newB = (bPercent<255)?bPercent:255;

        const RR = ((newR.toString(16).length==1)?'0'+newR.toString(16):newR.toString(16));
        const GG = ((newG.toString(16).length==1)?'0'+newG.toString(16):newG.toString(16));
        const BB = ((newB.toString(16).length==1)?'0'+newB.toString(16):newB.toString(16));

        return '#'+RR+GG+BB;
    }

    commarize(value) {
        const min = 1e3;
        // Alter numbers larger than 1k
        const posValue = Math.abs(value);
        if (posValue >= min) {
            const units = ['k', 'M', 'B', 'T'];

            const order = Math.floor(Math.log(posValue) / Math.log(1000));

            const unitname = units[(order - 1)];
            const num = value / 1000 ** order;

            // output number remainder + unitname
            return Math.round(num * 100) / 100 + unitname;
        }

        // return formatted original number
        return (Math.round(value * 100) / 100).toLocaleString();
    }

    getRandomColor(type){
        let letters = null;
        if(type == "light"){
            letters = 'ABCDEF'.split('');
        }else if(type == "dark"){
            letters = '0123456789'.split('');
        }else{
            letters = '0123456789ABCDEF'.split('');
        }
        let color = '#';
        for (let i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }
        return color;
    }
    isValidHexColor(hex) {
        //matches examples: #123AdF || #1AF
        const regEx = /[#]\b[a-fA-F,0-9]{6}\b|[#]\b[a-fA-F,0-9]{3}\b/;
        return regEx.test(hex);
    }
    async getUnixTimeStampMs() {
        const url = '/utc/';

        const res = await $.get(
            url
        );

        return res;
    }

    roundAccurately(number, decimalPlaces) {
        //function that uses exponential notation to prevent rounding errors
        //when rounding to a certain amount of decimal places https://www.jacklmoore.com/notes/rounding-in-javascript/
        return Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
    }

    titleCase(str) {
        return str.toLowerCase().split(' ').map(function(word) {
          return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    isFeatureFlagged(featureName){
        const featureFlagsString = process.env.MIX_ENABLED_FEATURE_FLAGS;
        if(featureFlagsString == null){
            return false;
        }
        let flagArray = featureFlagsString.split(',');
        return flagArray.includes(featureName);
    }

    async cacheWellImage(imageName) {
        const url = '/cache-wellhead-image';
        const res = await $.get(
            url,
            {
                'image_name': imageName
            },
            'json'
        );
        return res;
    }

    createTraceId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0, 
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }

    //returns the difference between two UTC times
    //in the format HH:mm:ss
    timeDiff(startTime, endTime, returnDays = false) {
        let seconds = moment.utc(endTime).diff(moment.utc(startTime), 'seconds');
        let days = 0;
        if (returnDays) {
            days = Math.floor(seconds/86400);
            seconds = seconds - days*86400;
        }
        const hours = Math.floor(seconds/3600);
        seconds = seconds - hours*3600;
        const minutes = Math.floor(seconds/60);
        seconds = seconds - minutes*60;
        let duration = days && returnDays ? `${days.toString().padStart(2, '0')}:` : '';
        duration+= `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return duration;
    }

    // Javascript sees 0 as "falsy" and often we do not want to consider 0 as falsy
    isFalsy = (value) => !value && value !== 0 && value !== [];
    isTruthy = (value) => !!value || value === 0;
    isNullOrEmpty = (list) => !list?.length;
    isIterable = (list) => !!list?.length && typeof list[Symbol.iterator] === 'function';
    zeroPadding = (value) => value < 10 ? "0" + value : value;

    truncateStr(text, count) {
        if (text != null && typeof text == 'string')
            return text.slice(0, count) + (text.length > count ? "..." : "");
        return '';
    }


    toast(message) {
        return component.showToast(message);
    }
    flushToasts(ids) {
        component.flushToasts(ids);
    }
    getActiveToasts() {
        return activeToasts;
    }

    alertModal(message) {
        component.alertModal(message);
    }

    iwsConfirm(modal) {
        // Send a uuid with this open request
        // Only a response with the matching uuid can resolve this request
        const _uuid = uuidv4();
        eventBus.$emit('iws-global-confirm-open', modal, _uuid);

        return new Promise((resolve, reject) => {
            _resolveGlobalConfirm = (res) => {
                if (res.uuid == _uuid)
                    resolve(res.answer)
            };
        });
    }

    //acts similar to filter_var and FILTER_VALIDATE_BOOLEAN in PHP
    //string of '0' is false, string of '1' is true, string of 'true' is true, string of 'false' is false, etc.
    validateBoolean(value) {
        if (typeof value === 'boolean') {
            return value;
        }
        if (typeof value === 'string') {
            const lowercaseValue = value.toLowerCase();
            return lowercaseValue === 'true' || lowercaseValue === '1';
        }

        if (typeof value === 'number') {
            return value !== 0;
        }

        return false;
    }
    
    // Takes two objects (or already stringified objects) and compares if they match (returns true when they do)
    compareObjects(objectA, objectB) {
        // Both are empty, they match enough
        if (!objectA && !objectB)
            return true;
        // One is falsy, abort before stringifies
        if (!objectA ^ !objectB)
            return false;

        // Support either 'object' being pre stringified for optimization purposes
        const stringifyA = typeof objectA == 'object' ? JSON.stringify(objectA) : objectA;
        const stringifyB = typeof objectB == 'object' ? JSON.stringify(objectB) : objectB;

        return stringifyA === stringifyB;
    }
}

export default new GlobalFunctions();
